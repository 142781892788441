<script setup>

    // ShipToContacts
    // Allows the user to select multiple contacts as the ship-to, generating a bulk order.


    // Import ----
    import { ref, computed, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { store } from '@/Store';

    import ShipToContact from './ShipToContact';
    import ConfirmAddressList from '@/site/areas/cart/components/ConfirmAddressList';
    import ShipToUserForm from '../forms/ShipToUserForm';

    // Constants ----
    const Display = {
        SUMMARY_ADDRESS_LIST: 'SUMMARY_ADDRESS_LIST',
        PREVIEW_ADDRESS_LIST: 'PREVIEW_ADDRESS_LIST',
        CONFIRM_ADDRESS_LIST: 'CONFIRM_ADDRESS_LIST',
        UPLOAD_ADDRESS_LIST: 'UPLOAD_ADDRESS_LIST'
    };

    const router = useRouter();

    const AddOption =
    {
        NEW_CONTACT: 'NEW_CONTACT',
        FROM_CONTACTS: 'FROM_CONTACTS',
        UPLOAD_FILE: 'UPLOAD_FILE'
    };


    // State ----
    const display = ref(Display.UPLOAD_ADDRESS_LIST);
    const selectedOption = ref(AddOption.UPLOAD_FILE);
    const form = reactive(new ShipToUserForm());


    const selectedAddresses = ref([]);
    let showUploadFile = ref(false);

    const personalAddresses = computed(()=>{
        return selectedAddresses.value;
    })

    //computed

    const isContactsSelected = computed(() => {
      return selectedAddresses.value.length > 0;
    })
    const haveAddressesUpdated = ref(false);

    // Handlers ----
    store.cart.onCartLoaded(() => {
        update();
    });

    store.cart.onCartUpdated(() => {
        update();
    });

    function update() {
        selectedAddresses.value = store.cart.current.shipToList.filter(a => a.marketingContactId !== null && a.marketingContactId !== undefined).map((address) => {
            return {
                ...address,
                id: address.marketingContactId
            }
        });
    }

    function onMyAddressConfirmClicked() {{
        // Pass only valid addresses
        display.value = Display.CONFIRM_ADDRESS_LIST;
        // upload the address here
        updatedAddresses(selectedAddresses.value);
    }}

    function onCancelClicked() {
        display.value = Display.UPLOAD_ADDRESS_LIST;
        selectedOption.value = AddOption.UPLOAD_FILE;
        showUploadFile.value = false;
    }

    async function updatedAddresses(updatedAddresses) {
        haveAddressesUpdated.value = true;
        await store.cart.setShipToMultiplContacts(updatedAddresses);
    }

    async function goToReviewPage(shipToContacts) {
        if (!haveAddressesUpdated.value) {
            await store.cart.setShipToMultiplContacts(shipToContacts);
        }

        if (shipToContacts.length == 1) {
            await updateToSingleContact(shipToContacts);
            router.push({ name: 'Review' });
            return;
        }

        if (await store.cart.updateMultiShipItemsQuantity()) {
            router.push({ name: 'Review' });
        }
    }

    async function updateToSingleContact(shipToContacts) {
        const shipTo = shipToContacts[0];
        form.model.addressee = shipTo.addressee;
        form.model.companyName = shipTo.companyName;
        form.model.addressLine1 = shipTo.addressLine1;
        form.model.addressLine2 = shipTo.addressLine2;
        form.model.addressLine3 = shipTo.addressLine3;
        form.model.addressLine4 = shipTo.addressLine4;
        form.model.city = shipTo.city;
        form.model.state = shipTo.state;
        form.model.postalCode = shipTo.postalCode;
        form.model.countryCode = shipTo.countryCode;
        await store.cart.setShipToModeToSingleContact()
        await form.submit(false);
    }

    function onAddressSelected(address) {
        if (address.addressee.length < 1 && address.firstName && address.lastName) {
            address.addressee = address.firstName + " " + address.lastName;
        }
        selectedAddresses.value.push(address);
    }

    function onAddressDeselected(address) {
        selectedAddresses.value = selectedAddresses.value.filter(x => x.id !== address.id);
    }


</script>

<template>

    <div class="ship-to-contacts">
        <template v-if="display === Display.CONFIRM_ADDRESS_LIST">
            <ConfirmAddressList :addressList="personalAddresses" @update="updatedAddresses">
                <template #empty>
                    Empty Addresses
                </template>
            </ConfirmAddressList>

            <div class="show-right">
                <PageButton class="me-2" :outline="true" @click="onCancelClicked">Back</PageButton>
                <PageButton class="me-2" :disabled="personalAddresses.length === 0" @click="goToReviewPage(personalAddresses)">Review</PageButton>
            </div>
        </template>

        <template v-else>
            <ShipToContact :can-edit="true" :multiSelect="true" :toggle="true" @selected="onAddressSelected" @deselected="onAddressDeselected" />
            <div class="show-right">
                <PageButton class="me-2" :disabled="!isContactsSelected" @click="onMyAddressConfirmClicked">Confirm Addresses</PageButton>
            </div>
        </template>
    </div>

</template>

<style lang="scss">

    .ship-to-contacts {
        .add-contacts {
            .sub-title {
                font-weight: $bold;
            }
        }

        .show-right {
            float: right
        }


        .add-options {
        }

        .add-option {
            border: 1px solid $gray-20;
            padding: 0.5rem;
            height: 10rem;
        }
    }
</style>