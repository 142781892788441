<script setup>
	// SearchPage
	// Main product search page

	// Constants ----
	const constants = {
		DEFAULT_TITLE: 'All Products', // Used when no category is selected
		DEFAULT_PAGE_SIZE: 12
	};

	import { Util } from '@dd-nucleus/nucleus-vue';

	// Components ----
	import ProductGridTile from '../../components/ProductGridTile';
	import ProductListTile from '../../components/ProductListTile';
	import MockProductGridTile from '../../components/MockProductGridTile';
	import MockProductListTile from '../../components/MockProductListTile';
	import { useRoute } from 'vue-router';

	// Imports ----
	import { reactive, computed } from 'vue';
	import { Search } from '@dd-nucleus/nucleus-vue';

	// State ----
	const selectedCategories = reactive([]);
	const route = useRoute();

	// Pre-build the search here, so we can hook into its events and properties.
	const search = new Search('products-for-user', 'tile', 'product-name', route.query['per-pg'] ?? constants.DEFAULT_PAGE_SIZE);

	const productsView = computed(() => route.query.view || 'list');
	const selectedFilters = reactive({ filters: [] });

	search.onResults(() => {
		const filters = [];
		if (search.getFilterValues('product-new').length === 0) {
			filters.push({
				id: 'product-new',
				value: 'New Items'
			});
		}
		if (search.getFilterValues('product-featured').length === 0) {
			filters.push({
				id: 'product-featured',
				value: 'Featured Items'
			});
		}
		if (search.getFilterValues('product-favorites').length === 0) {
			filters.push({
				id: 'product-favorites',
				value: 'My Favorites'
			});
		}
		const productSearch = search.getFilterValues('product-search-term');
		if (productSearch.length === 1 && Util.isNotEmpty(productSearch[0])) {
			filters.push({
				id: 'product-search-term',
				value: productSearch[0]
			});
		}
		const dates = search.getFilterValues('product-created-range');

		if (dates.length > 0 && Util.isNotEmpty(dates[0])) {
			const value = dates
				.map(date => {
					return new Date(date).toLocaleString().split(',')[0];
				})
				.join(' - ');
			filters.push({
				id: 'product-created-range',
				value: value
			});
		}
		selectedFilters.filters = filters;
	});

	const sortOptions = [
		{ id: 'product-newest-to-oldest', text: 'Newest to Oldest' },
		{ id: 'product-oldest-to-newest', text: 'Oldest to Newest' },
		{ id: 'product-itemNumber', text: 'Item Number (A-Z)' },
		{ id: 'product-itemNumber-desc', text: 'Item Number (Z-A)' },
		{ id: 'product-name', text: 'Item Name (A-Z)' },
		{ id: 'product-name-desc', text: 'Item Name (Z-A)' }
	];

	const pageSizeOptions = [12, 24, 36, 48, 60];

	// Handlers ----
	async function onPathChanged(items) {
		selectedCategories.splice(0, selectedCategories.length, ...items);
		// TODO: Revisit this funtionality to reset the page when the category changes.
		if (items.length && (items[0].id !== localStorage.getItem('prev_route') || !localStorage.getItem('prev_route'))) {
			search.setPage(0);
		}
		localStorage.setItem('prev_route', items[0].id);
	}

	async function clear() {
		await search.setFilterValue('product-search-term', '');
	}

	async function removeFilter(filter) {
		await search.removeFilter(filter.id);
	}

	async function removeAllFilters() {
		await search.clearFilters();
	}
</script>

<template>
	<PageTemplate>
		<div class="container root-page search-page">
			<SearchContainer :search="search" :defaultView="productsView" :deep-link="true">
				<div class="row">
					<div class="col col-3 filter-bar">
						<div class="filter-section d-flex">
							<TextBoxFilter filter-id="product-search-term" placeholder="Search by keyword" />
							<button class="btn btn-white btn-search" type="button" @click="clear"><i class="icon bi-x-lg"></i></button>
						</div>

						<div class="filter-section">
							<div class="header">Date Added</div>
							<DateRangeFilter filter-id="product-created-range" />
						</div>

						<div class="filter-section">
							<div class="header">Explore</div>
							<AddFilterCheckBox filter-id="product-featured" label="Featured Items" :values="['true']" />
							<AddFilterCheckBox filter-id="product-favorites" label="My Favorites" :values="['true']" />
							<AddFilterCheckBox filter-id="product-new" label="New Items" :values="['true']" />
						</div>

						<div class="filter-section">
							<CategoryFilter filter-id="product-category" :selection="selectedCategories" @changed="items => onPathChanged(items)" />
						</div>
					</div>

					<div class="col col-9">
						<h1>
							{{ selectedCategories.length === 0 ? constants.DEFAULT_TITLE : selectedCategories[0].name }}
							<span v-if="search.results.loaded && search.results.rows.length > 0" class="sub-title"
								>({{ search.results.startIndex }} to {{ search.results.endIndex }} of {{ search.results.totalCount }} results)</span
							>
						</h1>

						<div class="filter-bar">
							<TreePath :items="selectedCategories" empty-text="All Categories" @selected="items => onPathChanged(items)" />
						</div>

						<div class="d-flex">
							<button v-for="filter in selectedFilters.filters" :key="filter.value" type="button" class="btn btn-primary m-1">
								{{ filter.value }} <span class="badge badge-light" @click="removeFilter(filter)">X</span>
							</button>
							<p v-if="selectedFilters.filters.length > 0" :key="clear - all" class="clear-filter" @click="removeAllFilters">Clear All</p>
						</div>

						<div class="row mb-3">
							<div class="col"><SortOptionsList :options="sortOptions" /></div>
							<div class="col"><PageSizeList :options="pageSizeOptions" :reloadSearch="false" /></div>
							<div class="col"><ViewModeSelector /></div>
							<div class="col-4 text-end"><PageSelector :reloadSearch="false" /></div>
						</div>

						<SearchGrid>
							<!-- Template for each item in a grid view -->
							<template v-slot:grid="product">
								<ProductGridTile :product="product" />
							</template>

							<!-- Template for each item in a list view -->
							<template v-slot:list="product">
								<ProductListTile :product="product" />
							</template>

							<!-- Template for no results -->
							<template #empty> No products were found that match your criteria. </template>

							<!-- Template while results are initially loading -->
							<template #loading-grid>
								<MockSearchGrid>
									<MockProductGridTile />
								</MockSearchGrid>
							</template>

							<template #loading-list>
								<!-- TODO -->
								<MockSearchGrid>
									<MockProductListTile />
								</MockSearchGrid>
							</template>
						</SearchGrid>

						<div class="row">
							<div class="col text-end"><PageSelector :reloadSearch="false" /></div>
						</div>
					</div>
				</div>
			</SearchContainer>
		</div>
	</PageTemplate>
</template>

<style lang="scss">
	.search-page {
		h1 {
			color: $black;
		}

		.sub-title {
			font-weight: $normal;
			font-size: 50%;
		}

		.clear-filter {
			font-size: 1rem;
			margin: auto 4px;
			color: $color-1;
			cursor: pointer;
		}
	}

	/* TODO: Potentially refactor into a filter section component that supplies SCSS variables for headers, etc. */
	.filter-section {
		border-top: 1px solid $gray-20;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.filter-section:first-child {
		padding-top: 0rem;
		border-top: 0px;
		padding-bottom: 1rem;
	}

	.filter-section:last-child {
		border-bottom: 0px;
		padding-bottom: 0rem;
	}

	.filter-section .header {
		font-weight: $bold;
		margin-bottom: 0.5rem;
	}
</style>
